import { HttpErrorResponse } from '@angular/common/http';

export type ErrorCandidate = {
  name?: unknown;
  message?: unknown;
  stack?: unknown;
};

const objectToString: () => string = Object.prototype.toString;

function isBuiltin(wat: unknown, className: string): boolean {
  return objectToString.call(wat) === `[object ${className}]`;
}

export function isString(wat: unknown): wat is string {
  return isBuiltin(wat, 'String');
}

function isErrorOrErrorLikeObject(value: unknown): value is Error {
  if (value instanceof Error) {
    return true;
  }

  if (value === null || typeof value !== 'object') {
    return false;
  }

  const candidate: ErrorCandidate = value as ErrorCandidate;

  return (
    isString(candidate.name) &&
    isString(candidate.message) &&
    (undefined === candidate.stack || isString(candidate.stack))
  );
}

// this is copy from @sentry
export function extractHttpModuleError(error: HttpErrorResponse): string | Error {
  // The `error` property of http exception can be either an `Error` object, which we can use directly...
  if (isErrorOrErrorLikeObject(error.error)) {
    return error.error;
  }

  // ... or an`ErrorEvent`, which can provide us with the message but no stack...
  if (error.error instanceof ErrorEvent && error.error.message) {
    return error.error.message;
  }

  // ...or the request body itself, which we can use as a message instead.
  if (typeof error.error === 'string') {
    return `Server returned code ${error.status} with body "${error.error}"`;
  }

  // If we don't have any detailed information, fallback to the request message itself.
  return error.message;
}

export function extractError(error: Error): Error | undefined | string {
  if (error instanceof HttpErrorResponse) {
    return extractHttpModuleError(error);
  }
  if (typeof error === 'string' || isErrorOrErrorLikeObject(error)) {
    return error;
  }
  return undefined;
}
