import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const APP_RUNTIME_ID: InjectionToken<number> = new InjectionToken<number>('appRuntimeId', {
  factory: () => 0
});

export function provideAppRuntimeId(id: number): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: APP_RUNTIME_ID, useValue: id }]);
}
