import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { initWorkerMessage } from '../common/messages';
import { BrowserSideBlocController } from './browser-side-bloc-controller';
import { WorkerWrapper } from './worker-wrapper';
import { WorkerWrapperVirtual } from './worker-wrapper-virtual';

export function provideWorker(worker: Worker, appRuntimeId: number): EnvironmentProviders {
  worker.postMessage(initWorkerMessage(appRuntimeId));
  return makeEnvironmentProviders([
    {
      provide: WorkerWrapper,
      useFactory: () => new WorkerWrapper(worker)
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (browserSideBlocController: BrowserSideBlocController<any>) => () => {
        browserSideBlocController.init();
      },
      deps: [BrowserSideBlocController],
      multi: true
    }
  ]);
}

export function provideWorkerVirtual(appRuntimeId: number): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: WorkerWrapper,
      useClass: WorkerWrapperVirtual
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (workerWrapper: WorkerWrapper) => () => {
        workerWrapper.postMessage(initWorkerMessage(appRuntimeId));
      },
      deps: [WorkerWrapper],
      multi: true
    }
  ]);
}
