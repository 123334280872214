import { LadeskChatConfig } from '../types';

export const ladeskChatConfig: LadeskChatConfig = {
  perLangConfig: {
    en: {
      chatId: 'ca74c469',
      chatIdForLoggedInUser: 'ke4ycuhd'
    },
    pl: {
      chatId: 'e69f7b56',
      chatIdForLoggedInUser: '0xanec5i'
    },
    uk: {
      chatId: 'f0e44afc',
      chatIdForLoggedInUser: 'js16etgd'
    }
  },
  scriptUrl: 'https://lvbet.ladesk.com/scripts/track.js'
};
