import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { IconsLoaderService } from './internal/icons-loader.service';
import { SvgIconIdentifier } from './types';
import { versionsPerApp } from './versions-per-app';

type Config = {
  loadCountries?: boolean;
  loadSports?: boolean;
};

export function provideSvgIcons(svgIconsIdentifier: SvgIconIdentifier, config?: Config): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: (iconsLoader: IconsLoaderService) => async () => {
        iconsLoader.init(
          svgIconsIdentifier,
          versionsPerApp[svgIconsIdentifier],
          Boolean(config?.loadCountries),
          Boolean(config?.loadSports)
        );
      },
      deps: [IconsLoaderService],
      multi: true
    }
  ]);
}
