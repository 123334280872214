import { InjectionToken } from '@angular/core';
import { VirtualSportConfigCategorizedLoader, VirtualSportConfigLoader } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const VIRTUAL_SPORTS_LOADER: InjectionToken<VirtualSportConfigLoader> =
  new InjectionToken<VirtualSportConfigLoader>('VirtualSports', {
    factory: () => () => Promise.resolve([])
  });

// eslint-disable-next-line @typescript-eslint/naming-convention
export const VIRTUAL_SPORTS_CATEGORIZED: InjectionToken<VirtualSportConfigCategorizedLoader> =
  new InjectionToken<VirtualSportConfigCategorizedLoader>('VirtualSportsCategorized', {
    factory: () => () => Promise.resolve([])
  });

// eslint-disable-next-line @typescript-eslint/naming-convention
export const VIRTUAL_SPORTS_NEED_LOGGED_IN: InjectionToken<boolean> = new InjectionToken<boolean>(
  'VirtualSportsNeedLoggedIn',
  {
    factory: () => false
  }
);
