// import { defaultStackParser } from '@sentry/browser';
// import { parseStackFrames } from '@sentry/utils';
import { extractError, isString } from './extractors';

export type ErrorPrepared = {
  name: string;
  message?: string;
  stack?: string;
};

export function getErrorPrepared(error: Error | undefined): ErrorPrepared | undefined {
  if (!error) {
    return undefined;
  }
  const extractedError: string | Error | undefined = extractError(error);
  if (!extractedError) {
    return undefined;
  }
  if (isString(extractedError)) {
    return {
      name: 'UnknownError',
      message: extractedError
    };
  }
  return {
    name: extractedError.name,
    message: extractedError.message,
    stack: extractedError.stack
  };
}
