import { DeviceBaseType } from '@bcf-v2-utilities/device-info/types';

function mapDeviceToI18n(device: DeviceBaseType): string {
  if (device === 'mobile-web-twa') {
    return 'mobile-web';
  }
  return device;
}

export function createAppId(
  productType: 'sportsbook' | 'casino',
  brand: string,
  device: DeviceBaseType,
  lang: string
): string {
  return `${brand}/${lang}/${productType}-${mapDeviceToI18n(device)}`;
}

export function getTranslationsUrl(appId: string): string {
  return `https://storage.googleapis.com/translations-sportsbook.appspot.com/translations-bcf/${appId}.json`;
}
