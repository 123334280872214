function sanitizeOperatorToId(operatorName: string): string {
  return operatorName.toLowerCase().replaceAll(' ', '-');
}

export enum CasinoGameKind {
  slots = 'slots',
  liveCasino = 'liveCasino',
  tableGame = 'tableGame',
  jackpot = 'jackpot',
  mixed = 'mixed'
}

function determineKind(isLiveCasino: boolean, isTableGame: boolean, isJackpot: boolean): CasinoGameKind {
  if (isJackpot) {
    return CasinoGameKind.jackpot;
  }
  if (isTableGame) {
    return CasinoGameKind.tableGame;
  }
  if (isLiveCasino) {
    return CasinoGameKind.liveCasino;
  }
  return CasinoGameKind.slots;
}

export class CasinoGameDataKind {
  constructor(
    public readonly isSlots: boolean,
    public readonly isLiveCasino: boolean,
    public readonly isTableGame: boolean,
    public readonly isJackpot: boolean,
    public readonly singleKind: CasinoGameKind,
    public readonly gameKindRaw: CasinoGameKind | undefined
  ) {}

  public static create(gameKind: string, isJackpot: boolean): CasinoGameDataKind {
    const isSlot: boolean = gameKind === 'Slots';
    const isLiveCasino: boolean = gameKind.startsWith('Live');
    const isTableGame: boolean = !gameKind.includes('Live') && gameKind !== 'Slots';

    return new CasinoGameDataKind(
      isSlot,
      isLiveCasino,
      isTableGame,
      isJackpot,
      determineKind(isLiveCasino, isTableGame, isJackpot),
      gameKind as any
    );
  }
}

export class CasinoGameDataProvider {
  constructor(
    public id: string,
    public name: string
  ) {}

  public static fromJson(json: Record<string, string>): CasinoGameDataProvider {
    const providersMap: Record<string, string> = {
      'playn-go': `Play'n GO`
    };
    return new CasinoGameDataProvider(
      sanitizeOperatorToId(json['provider']),
      (providersMap[json['provider']] ?? json['provider']).replaceAll('-', ' ')
    );
  }
}

export class CasinoGameData {
  constructor(
    public readonly gameId: number,
    public readonly name: string,
    public readonly slugName: string,
    public readonly provider: CasinoGameDataProvider,
    public readonly tags: string[],
    public readonly iconName: string,
    public readonly kind: CasinoGameDataKind,
    public readonly isCoomingSoon: boolean,
    public readonly hasJackpot: boolean,
    public readonly demoModeForNotLoggedIn: boolean
  ) {}

  public static fromJson(json: Record<string, any>): CasinoGameData {
    return new CasinoGameData(
      json['id'],
      json['name'],
      json['slug'],
      CasinoGameDataProvider.fromJson(json),
      json['tags'],
      json['slug'],
      CasinoGameDataKind.create(json['kind'], json['jackpot']),
      json['coming_soon'],
      json['jackpot'],
      json['demo'] ?? true
    );
  }

  public static fromJsonArray(jsons: Record<string, any>[]): CasinoGameData[] {
    return jsons.map(CasinoGameData.fromJson);
  }
}
