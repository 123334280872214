/* eslint-disable @typescript-eslint/naming-convention */
import { InjectionToken } from '@angular/core';
import { WizardPostFormLayoutConfig } from './types';

export const WIZARD_POST_FORM_LAYOUT_CONFIG: InjectionToken<WizardPostFormLayoutConfig> =
  new InjectionToken<WizardPostFormLayoutConfig>('wizardPostFormLayoutConfig');

export const WIZARD_POST_SHOW_PROMOCODE_FIELD_ON_INIT: InjectionToken<boolean> = new InjectionToken<boolean>(
  'wizardPostShowPromocodeFieldOnInit'
);
