import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { WorkerSynchronizer } from './worker-synchronizer';
import { WorkerSynchronizerContract } from './worker-synchronizer-contract';
import { WorkerSynchronizerSimple } from './worker-synchronizer-simple';

@Injectable({ providedIn: 'root' })
export abstract class WorkerSynchronizerSpawner {
  private _sharedLocalSynchronizers: Record<string, ReplaySubject<unknown> | undefined> = {};

  constructor(private _workerSynchronizerMessenger: WorkerSynchronizerContract) {}

  public spawn<T>(channelName: string, debug?: boolean): WorkerSynchronizer<T> {
    this._sharedLocalSynchronizers[channelName] ??= new ReplaySubject(1);
    return new WorkerSynchronizer<T>(
      channelName,
      this._workerSynchronizerMessenger,
      this._sharedLocalSynchronizers[channelName] as ReplaySubject<any>,
      debug
    );
  }

  public spawnRaw(channelName: string): WorkerSynchronizerSimple {
    return new WorkerSynchronizerSimple(channelName, this._workerSynchronizerMessenger);
  }
}
