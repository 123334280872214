import { StatscoreVizuConfig } from '../types';

export const statscoreVizuConfig: StatscoreVizuConfig = {
  liveApiKeys: {
    match: '6448dbf89ff1c4b3e5cc7f7b',
    stats: '64ca018caa628434b0056684'
  },
  preApiKeys: {
    match: '64ca0188aa628434b0056683'
  }
};
