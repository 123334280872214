import { Injectable } from '@angular/core';
import { filterIsTruthy } from '@bcf-v2-shared/misc/rx-operators/filter-is-truthy';
import { BehaviorSubject, Observable, distinctUntilChanged, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CookiesForProviders {
  private _cookies$: BehaviorSubject<Record<string, string>> = new BehaviorSubject<Record<string, string>>({});

  public setCookies(cookies: Record<string, string>): void {
    this._cookies$.next(cookies);
  }

  public getRequestId(): Observable<string> {
    return this._cookies$.pipe(
      map((cookies: Record<string, string>) => cookies['BCF_SSR_REQ_ID'] ?? cookies['FASTIFY_REQ_ID']),
      distinctUntilChanged(),
      filterIsTruthy()
    );
  }
}
