/*
  WE HAVE ID AND CODE AS PROPERTY OPTIONAL HERE
  ON LVBETCOM (BECAUSE THIS CMS IS FUCKED UP) AND USES ID IN CMS DATABASE INSTEAD OD CODE.
  TO HELP WITH THIS WE USE FUNCTIO AND PIPE "findByCodeOrId"
*/

export class CmsDepositBonusOrFreespin {
  constructor(
    public readonly code: string | undefined,
    public readonly id: number | undefined,
    public readonly title: string,
    public readonly subtitle: string,
    public readonly description: string,
    public readonly termsContentHtml: string,
    public readonly imgPath: string
  ) {}

  public static fromJson(json: Record<string, any>): CmsDepositBonusOrFreespin {
    return new CmsDepositBonusOrFreespin(
      json['code'] ?? undefined,
      json['id'] ?? undefined,
      json['title'],
      json['subtitle'],
      json['description'],
      json['termsContentHtml'],
      json['imgPath']
    );
  }

  public static fromHeadlessJson(json: Record<string, any>): CmsDepositBonusOrFreespin {
    return new CmsDepositBonusOrFreespin(
      undefined,
      json['id'] ?? undefined,
      json['title'],
      json['subtitle'],
      json['description'],
      json['longTerms'],
      json['image']
    );
  }

  public static fromJsonArray(jsons: Record<string, any>[]): CmsDepositBonusOrFreespin[] {
    return jsons.map(CmsDepositBonusOrFreespin.fromJson);
  }

  public static fromHeadlessJsonArray(jsons: Record<string, any>[]): CmsDepositBonusOrFreespin[] {
    return jsons.map(CmsDepositBonusOrFreespin.fromHeadlessJson);
  }
}
