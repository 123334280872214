import { Inject, Injectable } from '@angular/core';
import { NAVIGATOR } from '@bcf-v2-platforms/platform-apis/navigator-provider';
import { WINDOW } from '@bcf-v2-platforms/platform-apis/window-provider';
import {
  DeviceApiType,
  DeviceApiTypeExtended,
  DeviceBaseType,
  DeviceCmsType,
  DeviceSpecificType
} from '@bcf-v2-utilities/device-info/types';
import { SharedSettings } from './shared-settings';

/*
   window and navigator has optional properties because we can check it on SSR
   and there window and navigator has empty object
*/
type NavigatorKind = {
  userAgent?: string;
  standalone: boolean;
};

type WindowKind = {
  MSStream?: unknown;
  matchMedia?(query: string): MediaQueryList;
};

export interface OverwritedDeviceType {
  deviceType?: string;
}

@Injectable({ providedIn: 'root' })
export class DeviceTypeInfo {
  constructor(
    @Inject(WINDOW) private _window: WindowKind,
    @Inject(NAVIGATOR) private _navigator: NavigatorKind,
    private _sharedSettings: SharedSettings
  ) {}

  public get typeMappedToApi(): DeviceApiType {
    if (this.type === 'mobile-web') {
      return 'mobile';
    }
    if (this.type === 'mobile-web-twa') {
      return 'mobile-app';
    }
    if (this.type === 'tv' || this.type === 'terminal-top') {
      return 'terminal';
    }
    if (this.type === 'banner' || this.type === 'banner-tv') {
      return 'web';
    }
    return this.type;
  }

  public get typeMappedToCms(): DeviceCmsType {
    if (this.type === 'mobile-web' || this.type === 'mobile-web-twa') {
      return 'mobile';
    }
    if (this.type === 'tv' || this.type === 'terminal-top') {
      return 'terminal';
    }
    if (this.type === 'banner' || this.type === 'banner-tv') {
      return 'web';
    }
    return this.type;
  }

  public get typeMappedToBpPlaceBetApi(): DeviceApiTypeExtended {
    if (this.type === 'tv' || this.type === 'terminal-top') {
      return 'terminal';
    }
    if (this.type === 'mobile-web') {
      if (this._isIOSPWA()) {
        return 'ios (PWA)';
      }
      if (this._isAndroidPWA()) {
        return 'android (PWA)';
      }
      if (this.isAndroidDevice()) {
        return 'android (mobile-web)';
      }
      if (this.isIosDevice()) {
        return 'ios (mobile-web)';
      }
    }
    if (this.type === 'mobile-web-twa') {
      if (this._isIOSNative()) {
        return 'ios (native)';
      }
      if (this._isAndroidNative()) {
        return 'android (native)';
      }
    }
    if (this.type === 'web') {
      if (this.isPWA()) {
        return 'web (PWA)';
      }
      return 'web (website)';
    }
    if (this.type === 'banner' || this.type === 'banner-tv') {
      return 'web (website)';
    }
    return this.type as DeviceApiTypeExtended;
  }

  public get type(): DeviceBaseType {
    if (this.isMobileApp()) {
      return 'mobile-web-twa';
    }
    return this._sharedSettings.device.type;
  }

  public get specificType(): DeviceSpecificType {
    if (this.type !== 'mobile-web' && this.type !== 'mobile-web-twa') {
      return undefined;
    }
    if (this.isIosDevice()) {
      return 'ios';
    }
    if (this.isAndroidDevice()) {
      return 'android';
    }
    if (this.isPWA()) {
      return 'pwa';
    }
  }

  public get isMobile(): boolean {
    return this.type === 'mobile-web' || this.type === 'mobile-web-twa';
  }

  public isMobileApp(): boolean {
    return this._sharedSettings.device.type === 'mobile-web-twa' || this._window.hasOwnProperty('cordova');
  }

  public isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(this._navigator.userAgent ?? '');
  }

  public isIosDevice(): boolean {
    return /iPad|iPhone|iPod/.test(this._navigator.userAgent ?? '');
  }

  public isAndroidDevice(): boolean {
    return /Android/.test(this._navigator.userAgent ?? '');
  }

  public isPWA(): boolean {
    return ['fullscreen', 'standalone', 'minimal-ui'].some(
      (displayMode: string) => this._window.matchMedia?.(`(display-mode: ${displayMode})`).matches
    );
  }

  // current app instance comes from app store
  private _isIOSNative(): boolean {
    return this.isIosDevice() && this.isMobileApp();
  }

  // current app instance comes from google play
  private _isAndroidNative(): boolean {
    return this.isAndroidDevice() && this.isMobileApp();
  }

  private _isIOSPWA(): boolean {
    return this.isIosDevice() && this.isPWA() && !this.isMobileApp();
  }

  private _isAndroidPWA(): boolean {
    return this.isAndroidDevice() && !this.isMobileApp() && this.isPWA();
  }
}
