import { ProductType } from '@bcf-shared-settings/settings/enums';
import { environmentCommon } from 'configs/environments/environment.common';

const brandName: string = 'lvbetpl';
// eslint-disable-next-line @typescript-eslint/typedef
export const environmentBase = {
  ...environmentCommon,
  pushNotificationsVapidKey: 'BAmkmFV1HF6MbpljBUGfxuv5K8rCmNYuTsPwmB3r7Bh7YLG_-mIVBiHj9Gkn5AFK4l1WPGISCRYs3EuYVRu_hDU',
  productType: ProductType.sportsbook,
  brandI18Name: brandName,
  brandCmsName: 'lvbetpl',
  brandName: 'Lv Bet',
  langs: ['en', 'pl', 'uk'],
  langsI18nMap: {
    en: 'en_US'
  },
  cmsHeadlessEnabled: true,
  cookiesFromUrlMap: {
    btag: 'lvcasino_btag'
  }
};
