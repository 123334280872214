import { EnvironmentSports } from '@bcf-shared-settings/settings/types';
import { environmentBase } from 'configs/environments-sports/sportsbook/lvbetpl/environment.base';

export const environment: EnvironmentSports = {
  ...environmentBase,
  matchOfferApiUrl: 'https://offer.lvbet.pl/client-api/v5/',
  matchOfferSocketUrl: 'wss://offer-ws.lvbet.pl/_v5/ws/update/',
  matchOfferSocketMatchStatsUrl: 'wss://offer-ws-statistics.lvbet.pl/_v1/',
  bpApiUrl: 'https://app.lvbet.pl/',
  bpApiUrlV2: 'https://betslips.lvbet.pl/client-betslips/v1/',
  bpApiUrlV2MyBets: 'https://betslips.lvbet.pl/client-betslips/v1/',
  bpApiUrlV2System: 'https://settings.lvbet.pl/client-system/v1/',
  resultsCenterApiUrl: 'https://results-center.beefee.co.uk/results-center/v3/',
  terminalAssetsUrl: 'https://terminal-famesportsbook.testowaplatforma123.net/terminal-assets/'
};
