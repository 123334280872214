import { isPlatformBrowser } from '@angular/common';
import { APP_INITIALIZER, EnvironmentProviders, Injector, makeEnvironmentProviders, PLATFORM_ID } from '@angular/core';
import {
  ngswAppInitializer,
  NgswCommChannel,
  SCRIPT,
  SwPush,
  SwRegistrationOptions,
  SwUpdate
} from '@angular/service-worker';
import { CurrentLang } from '@bcf-v2-utilities/current-lang/current-lang';

function ngswCommChannelFactory(opts: SwRegistrationOptions, platformId: string): NgswCommChannel {
  return new NgswCommChannel(
    isPlatformBrowser(platformId) && opts.enabled !== false ? navigator.serviceWorker : undefined
  );
}

export function optiSwAppInitializer(
  injector: Injector,
  script: string,
  options: SwRegistrationOptions,
  platformId: string,
  currentLang: CurrentLang
) {
  return async (): Promise<void> => {
    const lang: string = await currentLang.getBaseLang();
    ngswAppInitializer(injector, `${script}?lang=${lang}&p0=a0.js`, options, platformId)();
  };
}

export function provideOptiSW(script: string, options: SwRegistrationOptions = {}): EnvironmentProviders {
  return makeEnvironmentProviders([
    SwPush,
    SwUpdate,
    { provide: SCRIPT, useValue: script },
    { provide: SwRegistrationOptions, useValue: options },
    {
      provide: NgswCommChannel,
      useFactory: ngswCommChannelFactory,
      deps: [SwRegistrationOptions, PLATFORM_ID]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: optiSwAppInitializer,
      deps: [Injector, SCRIPT, SwRegistrationOptions, PLATFORM_ID, CurrentLang],
      multi: true
    }
  ]);
}
