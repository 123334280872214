import { DOCUMENT } from '@angular/common';
import {
  APP_INITIALIZER,
  EnvironmentProviders,
  Inject,
  makeEnvironmentProviders,
  ɵgetOriginalError
} from '@angular/core';
import { BCF_APP_ID, BCF_PRODUCTION_MODE, BCF_RELEASE_VERSION } from '@bcf-v2-configurators/tokens/app/token';
import { BcfAppId, BcfReleaseVersion } from '@bcf-v2-configurators/tokens/app/types';
import { WINDOW } from '@bcf-v2-platforms/platform-apis/window-provider';
import { Cookies } from '@bcf-v2-utilities/cookies-provider/cookies';
import { prepareUnhandledError } from '../common/prepare-unhandled-error';
import { sendToErrorReporting, sendToErrorUnhandledReporting } from '../common/send-to-error-reporting';

export function provideGcpErrorReportingBrowser(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory:
        (
          bcfProductionMode: boolean,
          bcfAppId: BcfAppId,
          bcfReleaseVersion: BcfReleaseVersion,
          window: Window,
          document: Document,
          cookies: Cookies
        ) =>
        () => {
          if (!bcfProductionMode) {
            return;
          }

          window.addEventListener('unhandledrejection', (error: PromiseRejectionEvent) => {
            sendToErrorUnhandledReporting(
              bcfAppId,
              bcfReleaseVersion,
              prepareUnhandledError(error),
              error,
              document.location.pathname,
              cookies.getEagerAll()
            );
          });

          window.addEventListener('error', (error: ErrorEvent) => {
            if (error.error) {
              const errorToSend: Error | undefined = ɵgetOriginalError(error.error) ?? error.error;
              sendToErrorReporting(
                bcfAppId,
                bcfReleaseVersion,
                errorToSend,
                error.error,
                document.location.pathname,
                cookies.getEagerAll()
              );
            }
          });
        },
      deps: [
        [new Inject(BCF_PRODUCTION_MODE)],
        [new Inject(BCF_APP_ID)],
        [new Inject(BCF_RELEASE_VERSION)],
        [new Inject(WINDOW)],
        [new Inject(DOCUMENT)],
        Cookies
      ],
      multi: true
    }
  ]);
}
