import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

export type EnvBp = {
  bpApiUrl: string;
  bpApiUrlV2?: string;
  bpApiUrlV2MyBets?: string;
  bpApiUrlV2System?: string;
  offerApiHttpUrl: string;
  offerApiWsUrl: string;
  offerMatchStatsApiWsUrl: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ENV_BP: InjectionToken<EnvBp> = new InjectionToken<EnvBp>('EnvBp');

export function provideEnvBp(envBp: EnvBp): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: ENV_BP, useValue: envBp }]);
}
