/* eslint-disable @typescript-eslint/typedef */
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { SharedSettings } from '@bcf-shared-settings/settings/shared-settings';
import { Cookies } from '@bcf-v2-utilities/cookies-provider/cookies';
import { addDays } from 'date-fns';

export const cookiesFromUrlDeps = [DOCUMENT, Cookies, SharedSettings, [new Inject(PLATFORM_ID)]];
export const cookiesFromUrlInit =
  (document: Document, cookies: Cookies, sharedSettings: SharedSettings, platformId: string) =>
  async (): Promise<void> => {
    if (!isPlatformBrowser(platformId)) {
      return;
    }
    if (!sharedSettings.environment.cookiesFromUrlMap) {
      return;
    }
    const cookiesFromUrlMap: Record<string, string> = sharedSettings.environment.cookiesFromUrlMap;
    const searchParams: URLSearchParams = new URLSearchParams(document.location.search);

    const hostname: string = document.location.hostname;
    const splitedByDot: string[] = hostname.split('.');
    const cookieDomain: string = splitedByDot.length === 3 ? `.${splitedByDot.slice(1).join('.')}` : `.${hostname}`;

    for (const [key, value] of Object.entries(cookiesFromUrlMap)) {
      if (searchParams.has(key)) {
        const searchValue: string = searchParams.get(key)!;
        cookies.set(value, searchValue, addDays(new Date(), 31), '/', cookieDomain);
      }
    }
  };
