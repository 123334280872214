import { DeviceBaseType, DeviceCmsType } from '@bcf-v2-utilities/device-info/types';
import { Device, Environment } from './types';

/**
 * temporary copy function here
 * instead of:
 * import { isDefined } from '@bcf-v2-shared/misc/pure-utils/is-defined';
 * copy because we want to make standalone library here
 */
function isDefined<T>(value: T): value is NonNullable<T> {
  return typeof value !== 'undefined';
}

export type WindowWithApiVersion = {
  apiVersion?: number | string;
};

export function resolveCmsApiUrl(
  environment: Environment,
  device: Device,
  deviceType: DeviceCmsType,
  window?: WindowWithApiVersion
): string {
  const cmsApiUrl: string =
    (environment.cmsApiUrl as any)[device.overrideForCms ?? deviceType] || environment.cmsApiUrl.default;
  if (isDefined(window?.apiVersion) && cmsApiUrl.includes('{apiVersion}')) {
    return cmsApiUrl.replace('{apiVersion}', `${window!.apiVersion}`);
  }
  return cmsApiUrl;
}

export function resolveShadowStylesUrl(environment: Environment, deviceType: DeviceBaseType): string {
  const shadowStylesUrl: string =
    (environment.shadowStylesUrl as any)[deviceType] || environment.shadowStylesUrl.default;
  return shadowStylesUrl;
}

export function resolveApiUrlWithVersion(url: string, window?: WindowWithApiVersion): string {
  if (isDefined(window?.apiVersion) && url.includes('{apiVersion}')) {
    return url.replace('{apiVersion}', `${window!.apiVersion}`);
  }
  return url;
}

export function isMobile(deviceType: DeviceBaseType): boolean {
  return deviceType === 'mobile-web' || deviceType === 'mobile-web-twa';
}
