import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WorkerWrapper {
  constructor(private _worker: Worker) {}

  public postMessage(message: Record<string, any>): void {
    try {
      this._worker.postMessage(this._tryParseMessage(message));
    } catch (err: any) {
      if (this._dismissError(message)) {
        return;
      }
      console.error('BROWSER_SIDE # cloning problem message', message);
      throw new Error('BROWSER_SIDE # problem with cloning message from browser to worker');
    }
  }

  public getMessage<T>(): Observable<T> {
    return new Observable((subscriber: Subscriber<T>) =>
      this._worker.addEventListener('message', (message: MessageEvent<T>) => subscriber.next(message.data))
    );
  }

  private _dismissError(message: Record<string, any>): boolean {
    if (message['channelName'] === '_workerSentryErrorHandler' && message['payload']?.['target'] instanceof WebSocket) {
      return true;
    }
    return false;
  }

  private _tryParseMessage(message: Record<string, any>): Record<string, any> {
    if (message.payload && message.payload instanceof HttpErrorResponse) {
      message.payload = {
        url: message.payload.url,
        status: message.payload.status,
        message: message.payload.message
      };
    }
    return message;
  }
}
