import { CasinoGameDataKind, CasinoGameKind } from './casino-game-data';

function mapToKind(json: Record<string, any>): CasinoGameDataKind {
  return new CasinoGameDataKind(json['isSlot'], !json['isSlot'], false, false, CasinoGameKind.slots, undefined);
}

export class CmsCasinoGameConfig {
  constructor(
    public readonly walletId: number,
    public readonly slugName: string,
    public readonly coverBgImage: string, // https://empsgourp.atlassian.net/browse/BCF-598
    public readonly name: string,
    public readonly isNotAvailable: boolean,
    public readonly isSlotGame: boolean,
    public readonly kind: CasinoGameDataKind,
    public readonly hideDetails: boolean,
    public readonly providerId: number,
    public readonly providerName: string,
    public readonly iconSrcSlug: string,
    public readonly isPromoted: boolean,
    public readonly isProviderNotAvailable: boolean
  ) {}

  public static fromJson(json: Record<string, any>): CmsCasinoGameConfig[] {
    return json['gamesList'].map((jsonInner: Record<string, any>) => {
      const urlParts: string[] = jsonInner['urlTitle'].split('/');
      const slugName: string = urlParts[urlParts.length - 2];
      return new CmsCasinoGameConfig(
        jsonInner['gameId'],
        slugName,
        '',
        jsonInner['gameTitle'],
        jsonInner['gameMaintenanceSingle'] === 'on',
        jsonInner['isSlot'],
        mapToKind(jsonInner),
        jsonInner['gameHiddenn'] === 'yes',
        jsonInner['gameProvider'],
        jsonInner['gameCategory'],
        jsonInner['gameIcon'],
        json['gamesRecommended'].includes(slugName),
        json['providersMaintenance'].includes(jsonInner['gameProvider'])
      );
    });
  }
}
