import { SvgIconIdentifier, SvgIconsVersionConfig } from './types';

export const versionsPerApp: Record<SvgIconIdentifier, SvgIconsVersionConfig> = {
  lvbet: {
    common: 1,
    app: 2
  },
  energiekasino: {
    common: 1,
    app: 1
  },
  energycasino: {
    common: 1,
    app: 1
  },
  hotslots: {
    common: 1,
    app: 1
  }
};
