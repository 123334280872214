import { Injectable } from '@angular/core';
import { filterIsTruthy } from '@bcf-v2-shared/misc/rx-operators/filter-is-truthy';
import * as R from 'rambdax';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { UserCountryContract } from './contract';

@Injectable({ providedIn: 'root' })
export class UserCountryService implements UserCountryContract {
  private _userCountryIp$: ReplaySubject<string | undefined> = new ReplaySubject<string | undefined>(1);
  private _getCountryIpShareRef$!: Observable<string>;

  public getCountry(): Observable<string> {
    return (this._getCountryIpShareRef$ ??= this._userCountryIp$.pipe(
      map((country: string | undefined) => country),
      filterIsTruthy(),
      map(R.toLower),
      distinctUntilChanged(),
      shareReplay(1)
    ));
  }

  public updateUserCountry() {
    return (country: string | undefined): void => this._userCountryIp$.next(country);
  }
}
