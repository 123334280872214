import { WizardPostFormLayoutConfig } from '../types';

export const wizardPostFormLayoutConfig: WizardPostFormLayoutConfig = {
  'complete-registration': [
    ['gender'],
    ['birth_date'],
    ['first_name'],
    ['last_name'],
    ['state'],
    ['address_1'],
    ['city', 'postal_code']
  ],
  'curacao-account-settings-step': [
    ['first_name'],
    ['last_name'],
    ['birth_date'],
    ['country'],
    ['currency'],
    ['promocode']
  ]
};
