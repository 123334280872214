import { Injectable } from '@angular/core';
import { random } from 'rambdax-v9';
import {
  filter,
  first,
  lastValueFrom,
  map,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscriber,
  Subscription,
  takeUntil
} from 'rxjs';
import {
  BrowserSideMessage,
  createInstanceMessage,
  destroyInstanceMessage,
  InstanceCreatedMessage,
  subjectNextMessage,
  subscribeToChannelMessage,
  SubscriptionCompleteMessage,
  SubscriptionErrorMessage,
  SubscriptionPayloadMessage,
  unsubscribeFromChannelMessage,
  workerReadyAck
} from '../common/messages';
import { ExtractedObservable, WorkerBlocChannels } from '../common/types';
import { WorkerBlocId } from '../worker-blocs.type';
import { TypedSubject } from './types';
import { WorkerReady } from './worker-ready';
import { WorkerWrapper } from './worker-wrapper';

function filterIsCorrectMessage(message: Record<string, any>): message is BrowserSideMessage {
  return Boolean(message.action);
}

function isFunction(x: unknown): boolean {
  return Object.prototype.toString.call(x) == '[object Function]';
}

@Injectable({ providedIn: 'root' })
export class BrowserSideBlocController<R extends WorkerBlocChannels> {
  private _subscribePayloadMessage$: Subject<SubscriptionPayloadMessage> = new Subject<SubscriptionPayloadMessage>();

  private _subscribeErrorMessage$: Subject<SubscriptionErrorMessage> = new Subject<SubscriptionErrorMessage>();

  private _subscribeCompleteMessage$: Subject<SubscriptionCompleteMessage> = new Subject<SubscriptionCompleteMessage>();

  private _instanceCreatedMessage$: Subject<InstanceCreatedMessage> = new Subject<InstanceCreatedMessage>();

  private _workerReadyForMessages$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  private _instanceCreatedUniqueIds: string[] = [];

  private _destroy$: Subject<string> = new Subject<string>();

  constructor(
    private _worker: WorkerWrapper,
    private _workerReady: WorkerReady
  ) {}

  public static getId(providedValue: WorkerBlocId): string {
    return `${new Date().getTime()}~${random(0, 1000)}~${providedValue}`;
  }

  public init(): void {
    this._listenForMessages();
  }

  private _listenForMessages(): void {
    this._worker
      .getMessage<BrowserSideMessage>()
      .pipe(filter(filterIsCorrectMessage))
      .subscribe((message: BrowserSideMessage) => {
        switch (message.action) {
          case 'workerReady':
            this._workerReadyForMessages$.next(true);
            this._workerReady.makeReady();
            this._worker.postMessage(workerReadyAck());
            break;
          case 'subscriptionPayload':
            this._subscribePayloadMessage$.next(message);
            break;
          case 'subscriptionError':
            this._subscribeErrorMessage$.next(message);
            break;
          case 'subscriptionComplete':
            this._subscribeCompleteMessage$.next(message);
            break;
          case 'instanceCreated':
            this._instanceCreatedUniqueIds.push(message.uniqueId);
            this._instanceCreatedMessage$.next(message);
            break;
        }
      });
  }

  public async sendCreateInstance(workerBlocId: WorkerBlocId, uniqueId: string): Promise<void> {
    await lastValueFrom(this._workerReadyForMessages$.pipe(first()));
    this._worker.postMessage(createInstanceMessage(workerBlocId, uniqueId));
  }

  public workerBlocInitialized(workerBlocId: WorkerBlocId, uniqueId: string): Observable<void> {
    const alreadyCreated$: Observable<boolean> = of(this._instanceCreatedUniqueIds.includes(uniqueId)).pipe(
      filter((found: boolean) => found === true)
    );

    return merge(
      alreadyCreated$,
      this._instanceCreatedMessage$.pipe(
        first(
          (message: InstanceCreatedMessage) => message.workerBlocId === workerBlocId && message.uniqueId === uniqueId
        )
      )
    ).pipe(map(() => undefined));
  }

  public subTo<K extends keyof R['rx'], V extends ExtractedObservable<R['rx'][K]>>(
    workerBlocId: WorkerBlocId,
    uniqueId: string,
    channelRx: K
  ): Observable<V> {
    return new Observable((subscriber: Subscriber<V>) => {
      const subscriptionId: string = `${new Date().getTime()}${random(0, 100000)}`;

      const isCorrectMessage = <T extends BrowserSideMessage>(message: T): boolean =>
        message.action !== 'instanceCreated' &&
        (message as any)?.subId === subscriptionId &&
        (message as any).uniqueId === uniqueId;

      const nextSub: Subscription = this._subscribePayloadMessage$
        .pipe(filter(isCorrectMessage))
        .subscribe((message: SubscriptionPayloadMessage) => subscriber.next(message.payload));

      const errorSub: Subscription = this._subscribeErrorMessage$
        .pipe(filter(isCorrectMessage))
        .subscribe((message: SubscriptionErrorMessage) => subscriber.error(message.error));

      const completeSub: Subscription = this._subscribeCompleteMessage$
        .pipe(filter(isCorrectMessage))
        .subscribe(() => subscriber.complete());

      const initSub: Subscription = this.workerBlocInitialized(workerBlocId, uniqueId)
        .pipe(takeUntil(this._destroy$.pipe(first((destroyUniqueId: string) => destroyUniqueId === uniqueId))))
        .subscribe(() =>
          this._worker.postMessage(subscribeToChannelMessage(workerBlocId, uniqueId, subscriptionId, channelRx))
        );

      return (): void => {
        initSub.unsubscribe();
        this._worker.postMessage(unsubscribeFromChannelMessage(workerBlocId, uniqueId, subscriptionId, channelRx));
        nextSub.unsubscribe();
        errorSub.unsubscribe();
        completeSub.unsubscribe();
      };
    });
  }

  public workerSubject<V extends TypedSubject<R['subjects'][K]>, K extends keyof R['subjects']>(
    workerBlocId: WorkerBlocId,
    uniqueIdOrFn: string | (() => string),
    subjectKey: K
  ): V {
    const resolveUniqueId = (uniqueIdOrFn: any): string => {
      if (isFunction(uniqueIdOrFn)) {
        return uniqueIdOrFn();
      }
      return uniqueIdOrFn;
    };
    return {
      next: (value: V) => {
        const resolvedUniqueId: string = resolveUniqueId(uniqueIdOrFn);
        this.workerBlocInitialized(workerBlocId, resolvedUniqueId)
          .pipe(takeUntil(this._destroy$.pipe(first((uniqueId: string) => uniqueId === resolvedUniqueId))))
          .subscribe(() => {
            this._worker.postMessage(subjectNextMessage(resolvedUniqueId, subjectKey, value));
          });
      }
    } as V;
  }

  public sendDestroyInstance(workerBlocId: WorkerBlocId, uniqueId: string): void {
    this._worker.postMessage(destroyInstanceMessage(workerBlocId, uniqueId));
    this._destroy$.next(uniqueId);
  }
}
