import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { BCF_APP_ID } from '@bcf-v2-configurators/tokens/app/token';
import { BcfAppId } from '@bcf-v2-configurators/tokens/app/types';
import { UseStore, clear, createStore, get, set, setMany } from 'idb-keyval';

@Injectable({ providedIn: 'root' })
export class CsrIconsDatabaseService {
  private _iconsStoreRef: UseStore | undefined;

  constructor(@Optional() @Inject(BCF_APP_ID) private _bcfAppId?: BcfAppId) {}

  private _createStore(): UseStore {
    return (this._iconsStoreRef ??= createStore(`${this._bcfAppId ?? 'local-test'}:svg-icons`, 'svg-icons'));
  }

  public async set<T>(key: IDBValidKey, value: T): Promise<void> {
    await set(key, value, this._createStore());
  }
  public async clear(): Promise<void> {
    await clear(this._createStore());
  }

  public get<T>(key: IDBValidKey): Promise<T | undefined> {
    return get(key, this._createStore());
  }

  public async setMany<T>(entries: [IDBValidKey, T][]): Promise<void> {
    await setMany(entries, this._createStore());
  }
}

@Injectable({ providedIn: 'root' })
export class SsrIconsDatabaseService {
  private _store: Record<string, any> = {};

  public async set<T>(key: IDBValidKey, value: T): Promise<void> {
    this._store[key.toString()] = value;
  }
  public async clear(): Promise<void> {
    this._store = {};
  }

  public get<T>(key: IDBValidKey): Promise<T | undefined> {
    return this._store[key.toString()];
  }

  public async setMany<T>(entries: [IDBValidKey, T][]): Promise<void> {
    for (const [key, value] of entries) {
      this._store[key.toString()] = value;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class IconsDatabaseService {
  constructor(
    @Inject(PLATFORM_ID) private _platformId: string,
    private _csrDatabase: CsrIconsDatabaseService,
    private _ssrDatabase: SsrIconsDatabaseService
  ) {}

  public set<T>(key: IDBValidKey, value: T): Promise<void> {
    if (isPlatformServer(this._platformId)) {
      return this._ssrDatabase.set(key, value);
    }
    return this._csrDatabase.set(key, value);
  }
  public clear(): Promise<void> {
    if (isPlatformServer(this._platformId)) {
      return this._ssrDatabase.clear();
    }
    return this._csrDatabase.clear();
  }

  public get<T>(key: IDBValidKey): Promise<T | undefined> {
    if (isPlatformServer(this._platformId)) {
      return this._ssrDatabase.get(key);
    }
    return this._csrDatabase.get(key);
  }

  public setMany<T>(entries: [IDBValidKey, T][]): Promise<void> {
    if (isPlatformServer(this._platformId)) {
      return this._ssrDatabase.setMany(entries);
    }
    return this._csrDatabase.setMany(entries);
  }
}
