import { ErrorCandidate } from './extractors';

export function prepareUnhandledError(error: PromiseRejectionEvent): ErrorCandidate | undefined {
  try {
    return {
      name: error.reason.name,
      message: error.reason.message,
      stack: error.reason.stack
    };
  } catch (err) {
    return undefined;
  }
}
