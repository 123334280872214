import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, first, map, race } from 'rxjs';
import { BrowserSideMessage } from '../common/messages';
import { WorkerWrapper } from './worker-wrapper';

@Injectable({ providedIn: 'root' })
export class WorkerReady {
  private _isReadyInternal$: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(private _worker: WorkerWrapper) {}

  public get isReady$(): Observable<void> {
    return race(
      this._isReadyInternal$,
      this._worker.getMessage<BrowserSideMessage>().pipe(
        first((message: BrowserSideMessage) => message?.action === 'workerReady'),
        map(() => undefined)
      )
    );
  }

  public makeReady(): void {
    this._isReadyInternal$.next(undefined);
  }
}
