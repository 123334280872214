import { Injectable } from '@angular/core';
import { CmsApiCountrySwitchable } from '@bcf-logic-api-access/api-country-switchable/cms-api-country-switchable';
import { CmsApi } from '@bcf-logic-api-access/api/cms-api';
import { CmsCasinoGameConfig } from '@bcf-logic-shared/models/cms-casino-game-config';
import { CmsDepositBonusOrFreespin } from '@bcf-logic-shared/models/cms-deposit-bonus-or-freespin';
import { CmsDepositMethod } from '@bcf-logic-shared/models/cms-deposit-method';
import { CmsExternalPage } from '@bcf-logic-shared/models/cms-external-page';
import { CmsPrivacyPolicy } from '@bcf-logic-shared/models/cms-privacy-policy';
import { CmsPromotionsSlider } from '@bcf-logic-shared/models/cms-promotions-slider';
import { CmsRegistrationWizardData } from '@bcf-logic-shared/models/cms-registration-wizard-data';
import { CmsRoute } from '@bcf-logic-shared/models/cms-route';
import { CmsWithdrawMethod } from '@bcf-logic-shared/models/cms-withdraw-method';
import { asPromise } from '@bcf-v2-shared/misc/rx-helpers/as-promise';
import { mapIfUndefined } from '@bcf-v2-shared/misc/rx-operators/map-if-undefined';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CmsRepository {
  constructor(
    private _cmsApi: CmsApi,
    private _cmsApiCountrySwitchable: CmsApiCountrySwitchable
  ) {}

  /** @deprecated remove it after switch to API2 */
  public getPromotedLeagues(): Promise<number[]> {
    return this._cmsApi.getPromotedLeagues();
  }

  /** @deprecated remove it after switch to API2 */
  public getPopularLeaguesForDisciplines(): Promise<Record<number, number[]>> {
    return asPromise(this._cmsApi.sportsDataPackage$.pipe(pluck('popularLeaguesInDiscipline')));
  }

  /** @deprecated remove it after switch to API2 */
  public async getPromotedLeaguesV2(): Promise<number[]> {
    const result: number[] = await asPromise(this._cmsApi.sportsDataPackage$.pipe(pluck('promotedLeagues')));
    if (result) {
      return result;
    }
    return this.getPromotedLeagues();
  }

  /** @deprecated remove after switch to API2 */
  public getEsportsTournaments(): Promise<number[]> {
    return asPromise(this._cmsApi.sportsDataPackage$.pipe(pluck('esportsTournaments')));
  }

  public getEsportsStreams(): Promise<Record<string, string>> {
    return asPromise(this._cmsApi.sportsDataPackage$.pipe(pluck('esportsStreams'), mapIfUndefined({})));
  }

  public getRegistrationWizardData(): Promise<CmsRegistrationWizardData> {
    return asPromise(
      this._cmsApiCountrySwitchable
        .getUserDataPackage()
        .pipe(pluck('registrationWizard'), map(CmsRegistrationWizardData.fromJson))
    );
  }

  public getPromotionsSlider(): Promise<CmsPromotionsSlider> {
    return asPromise(
      this._cmsApiCountrySwitchable
        .getUserDataPackage()
        .pipe(pluck('promotionsSlider'), mapIfUndefined({ slides: [] }), map(CmsPromotionsSlider.fromJson))
    );
  }

  public getPrivacyPolicy(): Promise<CmsPrivacyPolicy> {
    return asPromise(
      this._cmsApiCountrySwitchable.getUserDataPackage().pipe(pluck('privacyPolicy'), map(CmsPrivacyPolicy.fromJson))
    );
  }

  public getBetslipSuggestedStakes(): Promise<number[]> {
    return asPromise(this._cmsApiCountrySwitchable.getUserDataPackage().pipe(pluck('betslipSuggestedStakes')));
  }

  public getDepositMethods(): Promise<CmsDepositMethod[]> {
    return asPromise(
      this._cmsApiCountrySwitchable
        .getUserDataPackage()
        .pipe(pluck('deposit'), pluck('methods'), map(CmsDepositMethod.fromJsonArray))
    );
  }

  public getPaymentsLicenseHtml(): Promise<string | undefined> {
    return asPromise(this._cmsApiCountrySwitchable.getUserDataPackage().pipe(pluck('paymentsLicenseHtml')));
  }

  public getWithdrawMethods(): Promise<CmsWithdrawMethod[]> {
    return asPromise(
      this._cmsApiCountrySwitchable
        .getUserDataPackage()
        .pipe(pluck('withdraw'), pluck('methods'), map(CmsWithdrawMethod.fromJsonArray))
    );
  }

  public getDepositBonusesAndFreespins(): Promise<CmsDepositBonusOrFreespin[]> {
    return asPromise(
      this._cmsApiCountrySwitchable
        .getUserDataPackage()
        .pipe(pluck('deposit'), pluck('bonuses'), map(CmsDepositBonusOrFreespin.fromJsonArray))
    );
  }

  public getRoutesConfig(): Promise<CmsRoute[]> {
    return this._cmsApi.getRoutesConfig();
  }

  public getCmsExternalPageContent(url: string): Promise<CmsExternalPage | undefined> {
    return this._cmsApi.getCmsExternalPageContent(url);
  }

  public getCasinoGamesConfigs(): Promise<CmsCasinoGameConfig[]> {
    return asPromise(this._cmsApiCountrySwitchable.getCasinoGamesConfigs());
  }
}
