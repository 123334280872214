import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, lastValueFrom, map, of } from 'rxjs';
import { SvgIconIdentifier, SvgIconsVersionConfig } from '../types';
import { IconsDatabaseService } from './icons-database.service';
import { IconsNotifierService } from './icons-notifier.service';

@Injectable({ providedIn: 'root' })
export class IconsLoaderService {
  private _identifier: string | undefined;
  private _version!: SvgIconsVersionConfig;
  private _bucket: string = 'icons-storybook-v1';
  private _iconsCommonVersionDateKey: string = 'commonUpdatedAt';
  private _iconsAppVersionDateKey: string = 'appUpdatedAt';
  private _iconsCountriesVersionDateKey: string = 'countriesUpdatedAt';
  private _iconsSportsVersionDateKey: string = 'sportsUpdatedAt';

  private _loadCountries: boolean = false;
  private _loadSports: boolean = false;

  constructor(
    private _httpClient: HttpClient,
    private _iconsNotifier: IconsNotifierService,
    private _iconsDatabase: IconsDatabaseService
  ) {}

  public async init(
    identifier: SvgIconIdentifier,
    version: SvgIconsVersionConfig,
    loadCountries: boolean,
    loadSports: boolean
  ): Promise<void> {
    this._loadCountries = loadCountries;
    this._loadSports = loadSports;
    if (this._identifier) {
      return;
    }
    this._version = version;
    this._identifier = identifier;
    this._load();
  }

  private async _load(): Promise<void> {
    const versionsDates: [Date, Date, Date | undefined, Date | undefined] = await this._loadVersionsDates();
    const needUpdate: boolean = await this._isNeededUpdate(versionsDates);
    if (!needUpdate) {
      this._iconsNotifier.isReady$.next(true);
      return;
    }
    const icons: Record<string, string> = await this._loadIcons();
    await this._saveIconsInDb(icons, versionsDates);
    this._iconsNotifier.isReady$.next(true);
  }

  private _loadVersionsDates(): Promise<[Date, Date, Date | undefined, Date | undefined]> {
    return lastValueFrom(
      forkJoin([
        this._loadVersionDate(this._getCommonUpdatedAtUrl()),
        this._loadVersionDate(this._getAppUpdatedAtUrl()),
        this._loadCountries ? this._loadVersionDate(this._getCountriesUpdatedAtUrl()) : of(undefined),
        this._loadSports ? this._loadVersionDate(this._getSportsUpdatedAtUrl()) : of(undefined)
      ])
    );
  }

  private async _isNeededUpdate([common, app, countries, sports]: [
    Date,
    Date,
    Date | undefined,
    Date | undefined
  ]): Promise<boolean> {
    const commonVersionDate: Date | undefined = await this._iconsDatabase.get(this._iconsCommonVersionDateKey);
    if (commonVersionDate?.getTime() !== common.getTime()) {
      return true;
    }
    const appVersionDate: Date | undefined = await this._iconsDatabase.get(this._iconsAppVersionDateKey);
    if (appVersionDate?.getTime() !== app.getTime()) {
      return true;
    }
    const countriesVersionDate: Date | undefined = await this._iconsDatabase.get(this._iconsCountriesVersionDateKey);
    if (countries && countriesVersionDate?.getTime() !== countries.getTime()) {
      return true;
    }
    const sportsVersionDate: Date | undefined = await this._iconsDatabase.get(this._iconsSportsVersionDateKey);
    if (sports && sportsVersionDate?.getTime() !== sports.getTime()) {
      return true;
    }

    return false;
  }

  private async _loadIcons(): Promise<Record<string, string>> {
    type Icons = Record<string, string>;
    return lastValueFrom(
      forkJoin([
        this._httpClient.get<Icons>(this._getIconsCommonUrl()),
        this._httpClient.get<Icons>(this._getIconsAppUrl()),
        this._loadCountries ? this._httpClient.get<Icons>(this._getCountriesIconsUrl()) : of({}),
        this._loadSports ? this._httpClient.get<Icons>(this._getSportsIconsUrl()) : of({})
      ]).pipe(
        map(([common, app, countries, sports]: [Icons, Icons, Icons, Icons]) => {
          return {
            ...common,
            ...app,
            ...countries,
            ...sports
          };
        })
      )
    );
  }

  private async _saveIconsInDb(
    icons: Record<string, string>,
    [commonDate, appDate, countriesDate, sportsDate]: [Date, Date, Date | undefined, Date | undefined]
  ): Promise<void> {
    await this._iconsDatabase.clear();
    const preparedIcons: [key: string, svg: string][] = [];
    for (const [key, value] of Object.entries(icons)) {
      preparedIcons.push([key, value]);
    }
    await this._iconsDatabase.setMany(preparedIcons);
    await this._iconsDatabase.set(this._iconsCommonVersionDateKey, commonDate);
    await this._iconsDatabase.set(this._iconsAppVersionDateKey, appDate);
    if (countriesDate) {
      await this._iconsDatabase.set(this._iconsCountriesVersionDateKey, countriesDate);
    }
    if (sportsDate) {
      await this._iconsDatabase.set(this._iconsSportsVersionDateKey, sportsDate);
    }
  }

  private async _loadVersionDate(url: string): Promise<Date> {
    type VersionResponse = {
      updatedAt: Date;
    };
    const version: VersionResponse = await lastValueFrom(this._httpClient.get<VersionResponse>(url));
    return new Date(version.updatedAt);
  }

  private _getBaseUrl(): string {
    return `https://storage.googleapis.com/translations-sportsbook.appspot.com`;
  }

  private _getIconsCommonUrl(): string {
    return `${this._getBaseUrl()}/${this._bucket}/common/${this._version.common}-icons.json`;
  }

  private _getIconsAppUrl(): string {
    return `${this._getBaseUrl()}/${this._bucket}/${this._identifier}/${this._version.app}-icons.json`;
  }

  private _getCountriesIconsUrl(): string {
    return `${this._getBaseUrl()}/${this._bucket}/icons-countries/icons-countries.json`;
  }

  private _getSportsIconsUrl(): string {
    return `${this._getBaseUrl()}/${this._bucket}/icons-sports/icons-sports.json`;
  }

  private _getCountriesUpdatedAtUrl(): string {
    return `${this._getBaseUrl()}/${this._bucket}/icons-countries/updated-at-countries.json`;
  }

  private _getSportsUpdatedAtUrl(): string {
    return `${this._getBaseUrl()}/${this._bucket}/icons-sports/updated-at-sports.json`;
  }

  private _getCommonUpdatedAtUrl(): string {
    return `${this._getBaseUrl()}/${this._bucket}/common/${this._version.common}-updated-at.json`;
  }

  private _getAppUpdatedAtUrl(): string {
    return `${this._getBaseUrl()}/${this._bucket}/${this._identifier}/${this._version.app}-updated-at.json`;
  }
}
